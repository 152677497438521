<template>
  <div class="softwareComponentAdd">
    <LoadingPlaceholder v-if="loading" />
    <form v-else>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Software Component
        </h4>
        <hr class="m-0 mb-3">
        <label>Name (optional)</label>
        <input
          ref="name"
          v-model="softwareComponent.name"
          class="form-control"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t("type") }}</label>
        <Multiselect
          v-if="softwareComponentTypes"
          id="types"
          v-model="softwareComponent.type"
          :options="softwareComponentTypes"
          placeholder="Select a Software Component Type"
          :custom-label="customLabel"
          :close-on-select="true"
          :class="[
            { 'is-invalid': this.$validator.errors.has('ExternalResortId') },
          ]"
        />
        <hr class="m-0 mb-3">
        <label>Zabbix monitored URL</label>
        <input
          ref="url"
          v-model="softwareComponent.url"
          v-focus
          required
          pattern="https://.*|http://.*"
          :class="[
            'url form-control',
            { 'is-invalid': $validator.errors.has('Url') },
          ]"
          type="url"
        >
        <hr class="m-0 mb-3">
        <label>{{ "Monitoring Proxy" }}</label>
        <Multiselect
          id="proxies"
          v-model="softwareComponent.monitoringProxy"
          :options="monitoringProxies"
          :required="true"
          placeholder="Select a Proxy Type"
          :close-on-select="true"
          :class="[
            { 'is-invalid': !softwareComponent.monitoringProxy },
          ]"
        />
        <hr class="m-0 mb-3">
        <label>{{ "Configuration" }}</label>
        <textarea
          ref="confTextArea"
          v-model="softwareComponent.manualConfigurationJson"
          class="form-control"
          type="text"
          rows="10"
        />
        <hr class="m-0 mb-3">
        <button
          class="btn btn-primary float-right"
          :disabled="
            !isUrlValid ||
              !softwareComponent.url ||
              !softwareComponent.type ||
              !softwareComponent.monitoringProxy
          "
          @click.prevent="addSoftwareComponent()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t("add") }}
        </button>
        <div class="clearfix" />
      </div>
    </form>
  </div>
</template>

<script>
import { errorMixin } from "@/mixins/errorMixin.js";

export default {
  name: "SoftwareComponentAdd",
  components: {
    Multiselect: () => import("vue-multiselect"),
  },
  mixins: [errorMixin],
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      softwareComponent: {},
      loading: false,
      softwareComponentTypes: null,
      isUrlValid: false,
      monitoringProxies: ["proxy-production-aws", "no-proxy", "proxy-office-lakeside", "bastion-destos01"],
    };
  },
  watch: {
    "softwareComponent.url"() {
      if (this.$refs["url"]) {
        this.isUrlValid = this.$refs["url"].validity.valid;
      }
    },
  },
  created() {
    this.getSoftwareComponentTypes();
  },
  methods: {
    customLabel({ name }) {
      return name;
    },
    getSoftwareComponentTypes() {
      this.loading = true;
      this.axios
        .get("/SoftwareComponent/GetAllSoftwareComponentTypes")
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.softwareComponentTypes = response.data.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async addSoftwareComponent() {
      let postObject = {
        url: this.softwareComponent.url,
        installationId: this.installationId,
        type: this.softwareComponent.type,
        monitoringProxy: this.softwareComponent.monitoringProxy,
        name: this.softwareComponent.name,
      };

      if (this.softwareComponent.manualConfigurationJson) {
        try {
          var parseJSON = JSON.parse(
            this.softwareComponent.manualConfigurationJson
          );
          postObject.manualConfigurationJson = JSON.stringify(
            parseJSON,
            undefined,
            4
          );
        } catch {
          postObject.manualConfigurationJson =
            this.softwareComponent.manualConfigurationJson;
        }
      }

      postObject.typeId = this.softwareComponent.type.id;
      await this.axios
        .post("/SoftwareComponent/AddSoftwareComponent", postObject)
        .then(() => {
          this.$snotify.success(this.$t("softwareComponent.addedSuccessfully"));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.softwareComponentAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}

.url:invalid {
  border-color: rgba(203, 0, 2, 0.8);
  box-shadow: inset 0 0.0625em 0.125em rgba(0, 0, 0, 0.2),
    0 0 0.125em rgba(203, 0, 2, 0.8);
}
</style>
